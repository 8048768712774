import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "materials"
    }}>{`Materials`}</h3>
    <p>{`There are 2 types of materials in Country Life : Farming Season`}</p>
    <ul>
      <li parentName="ul">{`Seed : this type of material yields by planting in a field.`}</li>
      <li parentName="ul">{`Tool : this type of material yields when you use it with the corresponding animal in the farm.`}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/mat.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      